<template>
  <v-dialog v-model="show" persistent max-width="1200px">
    <v-card>
      <v-card-title>
        <span class="headline">Roles</span>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-2 mt-2">
          <v-col md="10">
            <v-text-field v-model="roleName" label="Role" />
          </v-col>
          <v-col md="auto" class="mt-2">
            <v-btn color="primary" @click="addRole">
              {{ btn }}
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels>
          <v-expansion-panel v-for="(role, i) in roles" :key="i">
            <v-expansion-panel-header
              color="#E8F5E9"
              disable-icon-rotate
              @click="getPermissions(role)"
            >
              {{ role.name }}
              <template v-slot:actions>
                <v-icon color="accent"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="text-right mt-1">
                <v-btn color="primary" @click="selectAllPermission()">
                  Select All
                </v-btn>
              </div>
              <v-row>
                <div
                  v-for="(permission, i) in permissions"
                  :key="i"
                  class="col"
                >
                  <v-checkbox
                    v-model="permission.selected"
                    :label="permission.value"
                  />
                </div>
              </v-row>
              <v-col class="text-right mt-4">
                <v-btn color="error" class="mx-2" @click="removeRole(role)">
                  Delete
                </v-btn>
                <v-btn color="primary" @click="updatePermission(role)">
                  Update Permissions
                </v-btn>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UserDataService from "@/services/UserDataService";

export default {
  props: ["show", "roles"],
  data: function () {
    return {
      roleName: "",
      permissions: [],
      btn: "Add",
    };
  },
  methods: {
    addRole() {
      if (this.btn === "Edit") {
      } else {
        UserDataService.CreateRole(this.roleName).then((response) => {
          if (response.data.isSuccess) {
            this.roles.unshift(response.data.outputObject);
            this.$toast.success("Role added successfully.", "Success", {
              position: "topRight",
            });
          }
        });
      }
      this.roleName = "";
    },
    editRole(role) {
      this.btn = "Edit";
      this.roleName = role;
    },
    deleteRole(role) {
      //   var productsCount = 0;
      //   zone.categories.forEach((element) => {
      //     productsCount = productsCount + element.products.length;
      //   });
      //   if (productsCount > 0) {
      //     alert("This zone have products");
      //   } else {
      //     ZoneDataService.delete(zone.id).then((response) => {
      //       var index = this.zones.findIndex((x) => x.id === zone.id);
      //       this.zones.splice(index, 1);
      //     });
      //   }
    },
    updatePermission(data) {
      var rolePermissions = {
        Role: data.name,
        Permissions: [],
      };
      this.permissions.forEach((x) => {
        if (x.selected === true) {
          rolePermissions.Permissions.push(x);
        }
      });
      UserDataService.updateRolePermissions(rolePermissions).then(
        (response) => {
          if (response.status === 200) {
            this.$toast.success("Role updated successfully.", "Success", {
              position: "topRight",
            });
          }
        }
      );
    },
    close() {
      this.roleName = "";
      this.btn = "Add";
      this.$emit("close");
    },
    getPermissions(role) {
      UserDataService.getRolePermissions(role.id).then((response) => {
        this.permissions = response.data.permissions;
      });
    },
    removeRole(role) {
      UserDataService.DeleteRole(role.name).then((response) => {
        if (response.data.isSuccess) {
          this.$toast.success("Role deleted successfully.", "Success", {
            position: "topRight",
          });
          const index = this.roles.findIndex((x) => x.id == role.id);
          this.roles.splice(index, 1);
        }
      });
      // UserDataService.DeleteRole(role)
    },
    selectAllPermission() {
      this.permissions.forEach((x) => {
        x.selected = true;
      });
    },
  },
};
</script>
