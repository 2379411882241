<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <!-- <app-btn class="mx-2" @click="dialogRoles = true">
        <v-icon>mdi-account-plus-outline</v-icon> Roles
      </app-btn> -->
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New User
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Users"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="users"
          :options.sync="options"
          :search.sync="search"
          :server-items-length="total"
          :loading="loading"
          multi-sort
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:top>
            <roles
              :show="dialogRoles"
              :roles="roles"
              @close="dialogRoles = false"
            />
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="UserForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.firstName"
                            label="First Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Last Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.userName"
                            label="Username"
                            :disabled="editedIndex > -1"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                          />
                        </v-col>
                        <!-- <v-col cols="12" v-if="editedIndex > -1">
                          <v-text-field
                            v-model="editedItem.currentPassword"
                            label="Current password"
                          />
                        </v-col> -->
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.password"
                            label="Password"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.role"
                            :items="roles"
                            item-text="name"
                            item-value="name"
                            label="Role"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>

            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import UserDataService from "@/services/UserDataService";
import Roles from "./components/Roles.vue";

export default {
  name: "DataTablesView",
  components: { Roles },
  data: () => ({
    permissions: localStorage.getItem("permissions"),
    rules: {
      required: (value) => !!value || "Required.",
      length15: (value) => value.length <= 15 || "Maximum 15 characters.",
    },
    dialog: false,
    dialogDelete: false,
    dialogRoles: false,
    options: {},
    loading: false,
    headers: [],
    users: [],
    roles: [
      "IceStation",
      "Supervisor",
      "Picker",
      "User",
      "Admin",
      "Coordinators",
      "HelpDesk",
    ],
    total: 0,
    editedIndex: -1,
    editedItem: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      conformPassword: "",
      role: "",
    },
    defaultItem: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      conformPassword: "",
      role: "",
    },
    search: "",
    awaitingSearch: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New User" : "Edit User";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = setTimeout(() => {
          this.getDataFromApi();
        }, 2000);
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.headers = [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "userName",
        },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Actions", align: "end", value: "actions", sortable: false },
      ];
    },

    getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.$http
        .post("Users/Paginated", {
          pageNumber: page,
          pageSize: itemsPerPage,
          search: this.search,
        })
        .then((response) => {
          this.users = response.data.data.items;
          if (this.users && this.users.length > 0) {
            this.users.forEach((user) => {
              this.getUserRole(user);
            });
          }
          this.total = response.data.data.totalCount;
          this.loading = false;
        })
        .catch(() => {
          this.users = [];
          this.total = 0;
          this.loading = false;
        });

      this.$http.get("/Roles").then((response) => {
        let roles = response.data.data;
        if (roles) {
          this.roles = roles
            .map((x) => x.name)
            .filter((x) => x !== "SuperUser");
        }
      });
    },
    getUserRole(user) {
      this.$http.get(`Users/${user.id}/Roles`).then((response) => {
        let roles = response.data.data;
        user.role = roles && roles.length > 0 ? roles[0] : "";

        let index = this.users.findIndex((x) => x.id === user.id);
        if (index > -1) {
          this.users.splice(index, 1, user);
        }
      });
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http.delete(`Users/${this.editedItem.id}`).then((response) => {
        if (response.data.success) {
          this.$toast.success(response.data.message, "Success", {
            position: "topRight",
          });
        } else {
          this.$toast.error(response.data.message, "Error", {
            position: "topRight",
          });
        }
      });
      UserDataService.delete(this.editedItem.id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("User deleted successfully.", "Success", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        });
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.UserForm.validate()) {
        if (this.editedIndex > -1) {
          // const data = this.editedItem;
          // const index = this.editedIndex;
          // UserDataService.update(data).then((response) => {
          //   if (response.status === 200) {
          //     this.$set(this.users, index, data);
          //     this.$toast.success("User updated successfully.", "Success", {
          //       position: "topRight",
          //     });
          //   }
          // });
          if (
            this.editedItem.password &&
            this.editedItem.password.trim().length > 0
          ) {
            this.$http
              .get("Users/ResetPassword", {
                params: {
                  userName: this.editedItem.userName,
                  password: this.editedItem.password,
                  conformPassword: this.editedItem.password,
                },
              })
              .then((response) => {
                if (response.data.data.success) {
                  this.$toast.success(response.data.data.message, "Success", {
                    position: "topRight",
                  });
                } else {
                  this.$toast.error(response.data.data.message, "Error", {
                    position: "topRight",
                  });
                }
              });
          }
        } else {
          this.editedItem.conformPassword = this.editedItem.password;
          this.$http.post("Users", this.editedItem).then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
              this.users.unshift(response.data.data);
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          });
        }
        this.close();
      }
    },
  },
};
</script>
